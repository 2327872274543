import React, { useCallback, useEffect } from "react";
import { useSearch } from "wouter";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import "./App.css";
import { useState } from "react";
import { getImages, getLocationData } from "./api/index";

function App() {
  const searchString = useSearch();
  const [imageLinks, setImageLinks] = useState([]);
  const [scrollDuration, setScrollDuration] = useState(10000);
  const [carouselReady, setCarouselReady] = useState(false);

  const parseQueryString = () => {
    const params = new URLSearchParams(searchString);
    const queryParams = {};
    for (const [key, value] of params) {
      queryParams[key] = value;
    }
    return queryParams;
  };

  const queryParams = parseQueryString();

  const prefetch = useCallback(async () => { 
    const data = await getLocationData(queryParams["location"]);
    if (data) {
      console.log("Location Data:", data);
      if (data.scroll_duration) {
        setScrollDuration(data.scroll_duration * 1000);
      }
    }

    const images = await getImages(queryParams["location"]);
    if (images) {
      console.log("IMAGES:", images.urls);
      setImageLinks(images.urls);
    }

    setCarouselReady(true);
  }, [queryParams]);

  useEffect(() => {
    prefetch();
  }, [prefetch]);

  const customRenderArrowPrev = (clickHandler, hasPrev, label) => 
    (
      <button 
        type="button" 
        onClick={clickHandler} 
        title={label} 
        className="absolute z-10 top-1/2 h-full left-0 transform -translate-y-1/2 bg-black bg-opacity-10 p-2 text-white text-4xl opacity-0 hover:opacity-100 transition-opacity duration-300"
      >
        &#9664;
      </button>
    );

  const customRenderArrowNext = (onClickHandler, hasNext, label) => 
    (
      <button 
        type="button" 
        onClick={onClickHandler} 
        title={label} 
        className="absolute top-1/2 h-full right-0 transform -translate-y-1/2 bg-black bg-opacity-10 p-2 text-white text-4xl opacity-0 hover:opacity-100 transition-opacity duration-300"
      >
        &#9654;
      </button>
    );

  const isVideo = (url) => {
    const videoExtensions = ['.mp4', '.mov', '.avi', '.webm'];
    return videoExtensions.some(ext => url.toLowerCase().endsWith(ext));
  };

  return (
    <div className="w-full min-h-screen relative">
      {/*
      <div className="bg-gray-300 absolute top-0 left-0 w-[500px] p-3 z-50">
        <p>Device ID: {queryParams["deviceId"]} | Brand: {queryParams["brand"]} | Location: {queryParams["location"]}</p>
      </div>
      */}
      <div className="">
        {queryParams["location"] ? (
          carouselReady ? (
          <Carousel 
            className="relative w-full" 
            showStatus={true} 
            showThumbs={false} 
            autoPlay={true} 
            interval={scrollDuration} 
            infiniteLoop
            showArrows={true}
            renderArrowPrev={customRenderArrowPrev}
            renderArrowNext={customRenderArrowNext}
            useKeyboardArrows={true}
            stopOnHover={false}
          >
            {imageLinks.map((mediaLink, i) => (
              <div key={i} className="w-screen h-screen flex items-center justify-center">
                {isVideo(mediaLink) ? (
                  <video
                    src={mediaLink}
                    className="w-full h-full object-contain"
                    controls
                    autoPlay
                    muted
                    loop
                  />
                ) : (
                  <img 
                    alt={`slide ${i}`} 
                    src={mediaLink} 
                    className="w-full h-full object-contain" 
                  />
                )}
              </div>
            ))}
          </Carousel>
          ) : (
          <div className="w-full h-screen flex items-center justify-center bg-gray-600">
            <p className="text-gray-200 text-3xl">
              Loading...
            </p>
          </div>
          )
        ) : (
          <div className="w-full h-screen flex items-center justify-center bg-gray-600">
            <p className="text-gray-200 text-3xl">
              Missing location parameter!
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;