import axios from "axios";
import supabase from "../config/supabaseClient";

export const getImages = async (locationSlug) => {
    try{
        const res = await axios.get(`https://fkkfqyoaljdmucdudprs.supabase.co/functions/v1/serve-campaign-content?location=${locationSlug}`)
        if(res){
            return res.data;
        }else{
            return false;
        }
    }catch(
        e
    ){
        return e;
    }
}

export const getLocationData = async (location) => {
    const { data, error } = await supabase
      .from('Location')
      .select('*')
      .eq('slug', location)
      .single();
  
    if (error) {
      console.error('Error fetching location data:', error);
      return null;
    }
  
    return data;
  };